<template>
  <div class="newsList">
    <div class="conent">
      <div class="lbt">
        <img
          src="../assets/img/2304231541350878911.jpg"
          v-if="$route.query.cid === $columnIdFile.ewztjy1"
        />
        <img
          src="../assets/img/bann2.jpg"
          v-else-if="$route.query.cid === $columnIdFile.ewsrxx1"
        />
        <img src="../assets/img/banner.jpg" v-else />
      </div>
      <div class="w1380 lmy">
        <div class="lmy_box">
          <div class="lmy-right" v-if="$route.query?.status !== 'ztsc'">
            <div class="lmy-right-list">
              <div class="zlm">
                <p class="news 2">NEWS</p>
                <p>
                  <span><img src="../assets/img/newsIcon.png" /></span>新闻资讯
                </p>
              </div>
              <div class="lmlb">
                <ul>
                  <li
                    v-for="(item, index) in lmList"
                    :key="index"
                    :class="currentIm == item.name ? 'current' : ''"
                    id="item"
                    @click="toNewsList(item.name)"
                  >
                    <span>{{ item.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            style="display: flex; justify-content: space-between"
            v-if="$route.query?.status === 'ztsc'"
          >
            <div class="columnName" v-if="currentIm">
              {{ currentIm }}
            </div>
            <div
              class="columnName"
              style="font-size: 1.04167vw; cursor: pointer"
              @click="ztSeeMore"
            >
              查看更多>
            </div>
          </div>
          <div class="lmy-center">
            <div class="text-detail" v-if="type == '文本'">
              <TextDetail :content="currentContent"></TextDetail>
            </div>
            <div class="video-detail" v-if="type == '视频'">
              <VideoDetail></VideoDetail>
            </div>
            <div class="pic-detail" v-if="type == '图片'">
              <PicDetail></PicDetail>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 相关阅读 -->
    <div class="xgyd w1380" v-if="type == '文本' || type == '图片'">
      <p class="fbt">Related Reading</p>
      <p class="bt">
        <i></i>
        <span class="name">相关阅读</span>
        <span class="gd"
          ><a @click="toNewsList(currentIm)">查看更多&gt;</a></span
        >
      </p>
      <ul class="list">
        <li
          class="list-item"
          v-for="(item, index) in tableList.slice(0, 6)"
          :key="index"
        >
          <div class="date">
            <p>
              {{
                $moment(item.publishTime).format("YYYY.MM.DD").split(".")[1]
              }}月{{
                $moment(item.publishTime).format("YYYY.MM.DD").split(".")[2]
              }}日
            </p>
            <p>
              {{
                $moment(item.publishTime).format("YYYY.MM.DD").split(".")[0]
              }}年
            </p>
          </div>
          <div class="list-list" @click="toDetail(item)">
            <a>{{ item.title }}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TextDetail from "./textDetail";
import VideoDetail from "./videoDetail";
import PicDetail from "./picDetail";
export default {
  components: {
    TextDetail,
    VideoDetail,
    PicDetail,
  },
  data() {
    return {
      currentContent: null, //选中的数据的内容
      tableList: [],
      type: "文本",
      did: "",
      cid: "",
      currentIm: "要闻速递",
      lmList: [
        { name: "要闻速递", type: "ywsd", cid: this.$columnIdFile.ywsd },
        { name: "通知公告", type: "tzgg", cid: this.$columnIdFile.tzgg },
        { name: "学术动态", type: "xsdt", cid: this.$columnIdFile.xsdt },
        { name: "媒体二外", type: "mtew", cid: this.$columnIdFile.mtew },
        { name: "校园看点", type: "xykd", cid: this.$columnIdFile.xykd },
        { name: "专题", type: "ztlm", cid: "" },
      ],
    };
  },
  created() {
    this.setScroll();
    this.currentIm = this.$route.query.tab || "要闻速递";
    this.cid = this.$route.query.cid;
    this.did = this.$route.query.id;
    this.getStory();
    if (this.currentIm !== "专题") {
      this.getList();
    }
  },
  methods: {
    async getStory() {
      const res = await this.API.basic.getStory(this.did);
      if (res) {
        this.currentContent = res.data;
      }
    },
    async getList() {
      this.tableList = [];
      const data = {
        pageNumber: 0,
        pageSize: 10,
        columnIds: this.cid, //
      };
      const res = await this.API.basic.getlist(data);
      if (res) {
        this.tableList = res.data.content;
      }
    },
    toDetail(item) {
      this.setScroll();
      this.currentContent = item;
      this.type = "文本";
    },
    // 滚动条初始化高度设置为0
    setScroll() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0;
      });
    },
    toNewsList(tabName) {
      localStorage.setItem("currentIm", tabName);
      this.$router.push({
        path: "/newsList",
        query: {},
      });
    },
    ztSeeMore() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.newsList {
  overflow-y: auto;
  background: #f5f5f5;
  padding-bottom: 50px;
  .w1380 {
    width: 1380px;
    margin: 0 auto;
  }
  .conent .lbt {
    // z-index: -1;
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
  .lmy {
    position: relative;
    background: #fff;
    margin-top: -128px;
    z-index: 1;
  }
  .lmy-right {
    border-bottom: 1px solid #eee;
  }
  .lmy-right-list {
    display: flex;
  }
  .lmy-right-list .zlm {
    width: 245px;
    padding: 20px 40px 0 40px;
    .news {
      font-size: 56px;
      text-align: center;
      color: #a12916;
    }
  }
  .lmy-right-list p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    text-align: center;
    height: 45px;
    line-height: 45px;
    color: #a12916;
  }
  .lmy-right-list p span img {
    margin-right: 5px;
  }
  .lmy-right-list ul {
    display: flex;
    width: 100%;
    height: 128px;
  }
  .lmy-right-list ul li {
    height: 128px;
    line-height: 128px;
    text-align: center;
    font-size: 20px;
    width: 166px;
    span {
      color: #333;
      cursor: pointer;
    }
  }
  .lmy-right-list ul li.current {
    background: #a12916;
    span {
      color: #fff;
      cursor: pointer;
    }
  }
  .lmy-right-list ul li:hover {
    background: #a12916;
    span {
      color: #fff;
      cursor: pointer;
    }
  }
  a {
    color: #333;
  }
  .columnName {
    font-size: 30px;
    text-align: left;
    color: #a12916;
    padding: 0.104167rem 0.208333rem;
    margin-bottom: 0.104167rem;
    // border-bottom: 0.005208rem solid #eee;
  }
  .lmy-center {
    margin-top: 25px;
    padding: 25px 0;
    // margin-bottom: 50px;
  }
  .lmy-center .lmy-left {
    width: 1030px;
    margin: 0 auto;
  }
  .lmy-center .lmy-left .lmy-left-top {
    position: relative;
    height: 479px;
  }
  .navRoute {
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333;
    margin-bottom: 40px;
  }
  .lmy-center .lmy-left .lmy-left-top img {
    width: 100%;
    height: 100%;
  }
  .lmy-center .lmy-left .lmy-left-top span {
    display: block;
    position: absolute;
    background: url("../assets/img/topNews.png") no-repeat;
    width: 97px;
    height: 41px;
    font-size: 14px;
    color: #fff;
    top: 30px;
    left: -10px;
    line-height: 50px;
    text-align: center;
  }
  .ttnr {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    background: url("../assets/img/maskBg1.png");
    background-repeat: repeat-x;
    color: #fff;
    width: 1030px;
    height: 165px;
    font-size: 12px;
    padding: 50px 135px 0 40px;
  }
  .ttnr-left {
    width: 60px;
    text-align: center;
    margin-top: 20px;
    margin-right: 28px;
  }
  .ttnr-left p:first-child {
    font-size: 22px;
  }
  .ttnr-right {
    width: 760px;
    .ttnr-right-bt {
      margin-bottom: 10px;
    }
  }
  .ttnr-right a {
    font-size: 22px;
    color: #fff;
  }
  .ttnr-right a:hover {
    color: #a12916;
  }
  .lmy-left-bottom {
    margin-top: 25px;
  }
  .lmy-left-bottom .news-list .news-list-item {
    height: 100px;
    border-top: 1px solid #ddd;
    background: #fff url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  .lmy-left-bottom .news-list .news-list-item:first-of-type {
    border-top: none;
  }
  .lmy-left-bottom .news-list .news-list-item .list-date {
    width: 50px;
    text-align: center;
    color: #a12916;
    margin-right: 50px;
    font-size: 12px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-date p:first-child {
    font-size: 34px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title {
    width: 730px;
    line-height: 180%;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title .li_bt {
    height: 25px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title .li_zy {
    height: 25px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
  }
  .lmy-left-bottom .news-list .news-list-item:hover {
    background: #a12916 url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    color: #fff;
    .list-date {
      color: #fff;
    }
    .list-title .li_bt {
      color: #fff;
    }
    .list-title .li_zy {
      color: #fff;
    }
  }
  .lmy-page {
    text-align: center;
    margin-top: 38px;
    padding-bottom: 65px;
  }
  ::v-deep .el-pager li.active {
    background-color: #a12916 !important;
    colre: #fff;
  }
  ::v-deep .el-pager li:hover {
    color: #a12916 !important;
  }
  ::v-deep .el-pagination button:hover {
    color: #a12916 !important;
  }
  .xgyd {
    margin-top: 50px;
  }
  .xgyd .fbt {
    color: #ababab;
    font-size: 24px;
    padding-left: 5px;
  }
  .xgyd .bt {
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
  }
  .xgyd .bt i {
    float: left;
    width: 4px;
    height: 30px;
    background: #ad2e1b;
    border-radius: 2px;
  }
  .xgyd .bt .name {
    font-size: 28px;
    color: #000;
    float: left;
    margin-left: 5px;
  }
  .xgyd .bt .gd {
    float: right;
    font-size: 16px;
    color: #a12916;
  }
  .xgyd .bt .gd a {
    float: right;
    font-size: 16px;
    color: #a12916;
  }
  .xgyd .list {
    display: flex;
    flex-wrap: wrap;
  }
  .xgyd .list li {
    width: 672.5px;
    height: 108px;
    background: #fff url("../assets/img/jt.png") no-repeat;
    background-position: 95% center;
    margin-top: 20px;
    padding: 10px 20px;
  }
  .xgyd .list li:nth-of-type(2n + 1) {
    margin-right: 35px;
  }
  .xgyd .list li .date {
    float: left;
    margin-top: 17px;
  }
  .xgyd .list li .date p {
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    width: 80px;
    text-align: center;
  }
  .xgyd .list li .date p:first-child {
    border-bottom: 2px solid #f9f9f9;
  }
  .xgyd .list li .list-list {
    float: left;
    height: 80px;
    margin-left: 20px;
    margin-top: 7px;
    border-left: 2px solid #f9f9f9;
    width: 440px;
    padding-top: 10px;
    line-height: 30px;
    padding-left: 10px;
    font-size: 18px;
  }
  .xgyd .list li:hover {
    width: 672.5px;
    height: 108px;
    background: #a12916 url("../assets/img/jt.png") no-repeat;
    background-position: 95% center;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
</style>
